async function fetchNoticeShow() {
  const showHidePageId = '1bbdcca31a0280e3b177c793fcbf0801';
  
  try {
    const res = await fetch(`https://ptx3fnt3feboxmczw25o2g3tvu0tlmej.lambda-url.ap-northeast-2.on.aws/?pageId=${showHidePageId}`          
    );
    const data = await res.json();
    console.log(`data ${JSON.stringify(data)}`);
    
    if (data.block) {
      for (const key in data.block) {
        const b = data.block[key];
        if (b.value?.properties?.title && b.value?.properties?.checked) {
          const title = b.value?.properties?.title[0][0];
          const checked = b.value?.properties?.checked[0][0];
          if (title === "show" && checked === "Yes") {
            return true;
          }
        }
      }
    }
  } catch (err) {
    console.error(JSON.stringify(err));
  }

  return false;
}

export default fetchNoticeShow;