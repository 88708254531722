import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MobileProvider } from './context/mobile';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-notion-x/src/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <MobileProvider>
        <App />
      </MobileProvider>    
    </Router>
  </React.StrictMode>
);

reportWebVitals();
