import React, { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion-x';
import { fetchNoticeContent } from '../api/fetchNoticeContent';

// Notion Embed
const NoticePageContent = ({ onRecordMapFetched }) => {
  const [recordMap, setRecordMap] = useState(null);

  useEffect(() => {
    fetchNoticeContent({ setRecordMap, onRecordMapFetched });
  }, [onRecordMapFetched]);

  if (!recordMap) return null;

  return (
    <NotionRenderer recordMap={recordMap} fullPage={false} darkMode={false} />
  );
};

export default NoticePageContent;
