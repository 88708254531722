export async function fetchNoticeContent({ setRecordMap, onRecordMapFetched }) {
  const contentPageId = '1bbdcca31a0280ac83b9f0a08c3e29b0';

  try {
    const res = await fetch(
      `https://ptx3fnt3feboxmczw25o2g3tvu0tlmej.lambda-url.ap-northeast-2.on.aws/?pageId=${contentPageId}`
    );
    const data = await res.json();
    setRecordMap(data);
    if (onRecordMapFetched) {
      onRecordMapFetched(data);
    }
  } catch (err) {
    console.error(`Error: ${err}`);
  }
}
